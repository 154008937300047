// --------------------------
// FONTS
// --------------------------

@import url('https://fonts.googleapis.com/css2?family=Spartan:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=EB+Garamond:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap');

// --------------------------
// VARIABLES
// --------------------------

// Colors
$color1: #F7EEE8;
$color2: #eff1f1;
$color3: #D0AF99;
$color4: #f69853;
$color5: #5F7478;
$color6: #ffffff;
$color7: #707070;

// Fonts
$font1: 'Spartan', sans-serif;
$font2: 'Spartan', sans-serif;
$font3: 'EB Garamond', serif;


// Header
$bg-header: $color1;
$size-logo: 25rem;

// Menu
$font-menu: $font1;
$color-menu: $color7;
$color-hover-menu: $color3;

// Slider
$slide-hauteur: 53rem;
$slide-titre-font: $font2;
$slide-texte-font: $font2;
$slide-typo-color: $color6;

// Presentation
$presentation-bg-color: $color6;
$presentation-h1-font: $font1;
$presentation-h1-font-size : 4rem;
$presentation-h1-color: $color7;
$presentation-p-font: $font2;
$presentation-p-font-size: 2rem;
$presentation-p-color: $color7;

// Actualites
$actualite-h2-font: $font1;
$actualite-h2-font-size: 3rem;
$actualite-h2-color: $color7;
$actualite-bg-color: $color2;
$actualite-content-bg-color: $color6;
$actualite-h3-font: $font2;
$actualite-h3-font-size: 2rem;
$actualite-h3-color: $color7;
$actualite-submit-bg-color: $color6;
$actualite-submit-font: $font3;
$actualite-submit-font-size: 1.8rem;
$actualite-submit-font-color: $color7;
$actualite-sas-bg-color: $color6;
$actualite-sas-content-bg-color: $color2;

// Bloc
$bloc-bg-color: $color6;
$bloc-h1-font: $font1;
$bloc-h1-font-size : 3rem;
$bloc-h1-color: $color7;
$bloc-p-font: $font2;
$bloc-p-font-size: 1.6rem;
$bloc-p-color: $color7;
$bloc-link-font-color: $color6;
$bloc-link-bg-color: $color5;
$bloc-link-bg-hover-color: $color6;

// Contact
$contact-bg: $color6;
$contact-font: $font2;
$contact-font-size: 1.8rem;
$contact-submit-font: $font3;
$contact-submit-font-size: 1.8rem;
$contact-submit-font-color: $color7;
$bloc-link-font-color: $color6;
$contact-submit-bg-color: $color1;
$contact-bloc-bg: $color1;
$contact-bloc-font-color: $color7;

// Footer
$footer-bg-color: $color5;
$footer-font: $font2;
$footer-font-size: 1.3rem;
$footer-font-color: $color6;
$footer-reseau-size: 3.5rem;
$footer-reseau-font: $font1;
$footer-reseau-font-size: 1.6rem;

// Transitions
$transition: all 0.3s;

// Small tablets and large smartphones (landscape view)
$screen-sm-min: 576px;
// Small tablets (portrait view)
$screen-md-min: 768px;
// Tablets and small desktops
$screen-lg-min: 992px;
// Large tablets and desktops
$screen-xl-min: 1260px;

// --------------------------
// GENERAL
// --------------------------

html {font-size: 62.5%;}

body {
  background-color: $color6;
}

input:focus {
  outline: none;
}

* {
  outline: none;
}

button:focus {
  outline: 0;
}

.pad-top-4 {
  padding-top: 4rem;
}

.site-content {
  margin-top: 8rem;
}

main {
  padding-bottom: 0 !important;
}

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}

@media (min-width: 1260px) {
  .container {
    max-width: 1260px;
  }
}

h1 {
  font-family: $font1;
  font-size: 4rem;
  line-height: 4.8rem;
  font-weight: 300;
  color: $color7;
}

h2 {
  font-family: $font1;
  font-size: 4rem;
  line-height: 4.8rem;
  font-weight: 300;
  color: $color7;
  text-transform: uppercase;
}

h3 {
  font-family: $font2;
  font-size: 2rem;
  color: $color7;
  font-weight: normal;
  text-transform: uppercase;
}

h4 {
  font-family: $font2;
  font-size: 2rem;
  color: $color7;
  font-weight: normal;
}

h5 {
  font-family: $font2;
  font-size: 2rem;
  color: $color7;
  font-weight: bold;
}

h6 {
  font-family: $font2;
  font-size: 1.8rem;
  color: $color7;
  font-weight: bold;
  text-transform: uppercase;
}

p, li, a {
  font-family: $font2;
  font-size: 1.6rem;
  color: $color7;
  line-height: 2.8rem;
}

a {
  text-decoration: underline;
  color: $color7;
}

a:hover {
  text-decoration: none;
  color: $color7;
}

@media only screen and (max-width: $screen-md-min) {
  h1, h2 {
    font-size: 3rem !important;
    line-height: 3.8rem;
  }

  h3, h4, h5 {
    font-size: 1.9rem;
  }

  h6 {
    font-size: 1.6rem;
  }

  p {
    font-size: 1.3rem;
  }
}

img {
  max-width: 100%;
}

iframe {
  max-width: 100%;
}

.col-no-pad {
  padding: 0;
}

.grecaptcha-badge {
  display: none !important;
}

.bloc-center {
  text-align: center;

  p {
    text-align: center !important;
  }

  div {
    text-align: center;
  }
}

.img-center {
  display: block;
  margin: 2rem 0 2rem 0;
}

// --------------------------
// HEADER
// --------------------------

.navbar {

  background-color: $bg-header;
  box-shadow: 0 0.5rem 0.5rem rgb(0 0 0 / 5%);
  padding: 1.5rem 1rem 0 1rem;

  .navbar-brand {

    padding: 0;
    position: relative;
    z-index: 1;

    img {
      height: $size-logo;
      width: auto;
      margin: 0 0 1.5rem 0;
      transition: $transition;
    }

    @media only screen and (max-width: $screen-md-min) {
      img {
        height: 10rem;
      }
    }

  }

  #main-nav {

    padding-top: 5rem;
    transition: $transition;

    .navbar-nav {

      li {

        position: relative;
        padding: 0 1.5rem;

        a {
          color: $color-menu !important;
          font-family: $font-menu;
          font-size: 1.6rem !important;
          position: relative;
          text-transform: uppercase;
          text-decoration: none;

          &::after {
            content: '';
            position: absolute;
            left: 50%;
            bottom: 0;
            transform: translateX(-50%) scaleX(0);
            transform-origin: 50% 50%;
            width: 100%;
            height: 1px;
            background-color: $color-hover-menu;
            transition: transform 250ms;
          }

          &:hover {

            &::after {
              transform: translateX(-50%) scaleX(1);
            }

          }

        }

      }

      li:hover a {
        color: $color-hover-menu !important;
        transition: $transition;
      }

      li::after {
        content: "";
        position: absolute;
        right: 0;
        top: 3px;
        bottom: 8px;
        width: 1px;
        background-color: $color-menu;
        opacity: 0;
        transition: $transition;
      }

      li:last-of-type::after {
        content: "";
        position: absolute;
        right: 0;
        top: 8px;
        bottom: 8px;
        width: 0px;
        background-color: $color-menu;
        transition: $transition;
      }

      li::before {
        content: "";
        position: absolute;
      }

      li:hover::before {
        content: "";
        position: absolute;
      }

    }

  }

}

.little-padding {
  padding-top: 3rem !important;
  transition: $transition !important;
}

.scrolled {

  padding: 1rem 1rem 0 1rem;

  .navbar-brand {

    img {
      height: 10rem;
      width: auto;
      margin: 0 0 1.5rem 0;
      transition: $transition;
    }

  }

}

.navbar-toggle {
  display: none;
}

@media only screen and (max-width: $screen-xl-min) {

  .navbar {

    #main-nav {
      position: absolute;
      right: -30px;
      left: -30px;
      top: 0;
      background-color: $bg-header;
      margin: 5rem 0 0 0;
      z-index: 0;
      padding-top: 9rem;

      .navbar-nav {

        li {
          padding: 1rem 1.5rem;

          a:hover:after {
            display: none;
          }

        }

        li::after, li:hover::after {
          width: 100px !important;
          height: 1px;
          right: 50% !important;
          transform: translateX(50%);
          top: inherit !important;
          bottom: 0 !important;
        }

        li:last-of-type::after, li:last-of-type:hover::after {
          content: none !important;
        }

        li:hover::before {
          content: none !important;
        }

        li > a, li.current_page_item > a {
          color: white;
          margin: 0 3rem 0 3rem;
          padding: 1rem 0 1rem 0;
          font-size: 1.5rem;
          text-align: center;
        }

      }

    }

  }

  .navbar-toggle .icon-bar {
    display: block;
    height: 2px;
    border-radius: 1px;
    position: relative;
    width: 33px !important;
    transition: all 0.2s;
  }

  .navbar-toggle {
    display: block;
    border: 0;
    border: none;
    background: transparent !important;
    z-index: 200;

    .icon-bar {
      width: 22px;
      transition: all 0.2s;
    }

    .top-bar {
      transform: rotate(45deg);
      transform-origin: 5% 5%;
    }

    .middle-bar {
      opacity: 0;
    }

    .bottom-bar {
      transform: rotate(-45deg);
      transform-origin: 7% 90%;
    }

  }

  .navbar-toggle:hover {
    background: transparent !important;
  }

  .navbar-toggle:focus {
    outline: none;
  }

  .navbar-toggle.collapsed .top-bar {
    transform: rotate(0);
  }

  .navbar-toggle.collapsed .middle-bar {
    opacity: 1;
  }

  .navbar-toggle.collapsed .bottom-bar {
    transform: rotate(0);
  }

  .icon-bar {
    background-color: $color-menu !important;
    margin-bottom: 8px;
  }

}

@media only screen and (max-width: $screen-md-min) {
  .navbar-brand {
    padding-left: 15px !important;
  }
  .navbar-toggle {
    padding-right: 15px !important;
  }
}

// --------------------------
// SLIDER
// --------------------------

#slider {

  margin-top: 27rem;
  height: $slide-hauteur;
  overflow: hidden;

  .slider-container {

    padding: 0;

    .slider-div {
      width: 100%;
      height: $slide-hauteur;
      background-size: cover !important;
      position: relative;

      .slider-text {
        position: absolute;
        bottom: 0;
        background-color: rgba(67, 105, 91, 0.4);
        width: 100%;
        min-height: 10rem;

        p, h2 {
          text-align: center;
          color: #ffffff;
          font-weight: 300;
        }

        h2 {
          font-family: $slide-titre-font;
          font-size: 3.6rem;
          line-height: 4rem;
          font-weight: 300;
          margin: 1rem 0 0 0;
          padding: 1.5rem;
        }

        p {
          font-family: $slide-texte-font;
          font-size: 1.8rem;
          margin: 0 0 1rem 0;
          padding: 1.5rem;
        }
      }
    }

    .slick-next::before, .slick-prev::before {
      content: '';
    }

    .slick-next {
      font-size: 0;
      line-height: 0;
      position: absolute;
      top: calc(50% - 2.5rem);
      display: block;
      width: 13rem;
      height: 8rem;
      background: url(/img/arrow-right.svg) no-repeat center center;
      background-size: 11rem;
      background-position-x: 2rem;
      right: 6rem !important;
      background-color: transparent;
      border: 0;
      transition: $transition;
    }

    .slick-prev {
      font-size: 0;
      line-height: 0;
      position: absolute;
      top: calc(50% - 2.5rem);
      display: block;
      width: 13rem;
      height: 8rem;
      background: url(/img/arrow-left.svg) no-repeat center center;
      background-size: 11rem;
      background-position-x: 0;
      left: 6rem !important;
      background-color: transparent;
      border: 0;
      z-index: 1;
      transition: $transition;
    }

    @media only screen and (min-width: $screen-md-min) {
      .slick-next:hover {
        right: 5rem !important;
        transition: $transition;
      }

      .slick-prev:hover {
        left: 5rem !important;
        transition: $transition;
      }
    }

  }

}

@media only screen and (max-width: $screen-md-min) {
  #slider {
    height: 40rem;
    overflow: inherit;

    .slider-container {

      .row {
        margin: 0;
      }

      .slider-div {
        height: 40rem;

        .slider-text {

          h2 {
            font-size: 3rem;
            line-height: 4rem;
            padding: 0;
          }

          p {
            font-size: 1.8rem;
          }
        }
      }

      .slick-next, .slick-next:active, .slick-next:focus, .slick-prev, .slick-prev:active, .slick-prev:focus {
        /*top: 100%;*/
        width: 7rem;
        height: 7rem;
        background-size: 5rem;
        z-index: 20;
      }
    }
  }
}

// --------------------------
// PRESENTATION
// --------------------------

#presentation {

  padding: 10rem 0;
  background-color: $presentation-bg-color;

  h1 {
    font-family: $presentation-h1-font;
    font-size: $presentation-h1-font-size;
    padding: 1.5rem;
    color: $presentation-h1-color;
    text-align: center;
  }

  @media only screen and (max-width: $screen-md-min) {
    h1 {
      padding: 3rem 1.5rem 1.5rem 1.5rem;
    }
  }

  p {
    font-family: $presentation-p-font;
    font-size: $presentation-p-font-size;
    color: $presentation-p-color;
    text-align: center;
    padding: 1.5rem 1.5rem 1.5rem 1.5rem;
    margin-bottom: 0;
  }

}

// --------------------------
// BLOCS
// --------------------------

.bloc {

  .row {
    padding-top: 10rem;
    padding-bottom: 8rem;
  }

  h1 {
    font-family: $bloc-h1-font;
    font-weight: bold;
    font-size: $bloc-h1-font-size;
    line-height: 4rem;
    padding: 0;
    color: $bloc-h1-color;
    text-align: left;
  }

  h2 {
    padding: 2.5rem 0 1.5rem 0;
  }

  p {
    font-family: $bloc-p-font;
    font-size: $bloc-p-font-size;
    color: $bloc-p-color;
    text-align: left;
    padding: 1.5rem 0;
    margin-bottom: 0;
  }

  .bloc-link {
    font-family: $font3;
    font-size: $bloc-p-font-size;
    padding: 1rem 3rem 0.8rem 3rem;
    color: $bloc-link-font-color;
    border: 1px solid $bloc-link-bg-color;
    background-color: $bloc-link-bg-color;
    text-decoration: none;
  }

  .bloc-link:hover {
    color: $bloc-link-bg-color;
    text-decoration: none;
    background-color: transparent;
    transition: $transition;
  }

  img {
    width: 100%;
    height: auto;
  }

  .video-container {
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 30px;
    height: 0;
    overflow: hidden;
    margin: 0;
  }

  .video-container iframe, .video-container object, .video-container embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

}

// --------------------------
// CONTACT
// --------------------------

#contact {

  padding: 10rem 0 10rem 0;
  background-color: $contact-bg;

  .contact-label {
    margin-bottom: .5rem;
    width: 35%;
    text-align: right;
    float: left;
    padding-top: 12px;
    padding-right: 10px;
    font-weight: 300;
    height: 35px;
    font-family: $contact-font;
    font-size: $contact-font-size;
  }

  .wpcf7-form-control-wrap {
    display: flex;
  }

  @media only screen and (max-width: $screen-lg-min) {
    .contact-label {
      width: 100%;
      text-align: left;
    }
    .wpcf7-form-control-wrap {
      width: 100%;
    }
  }

  .contact-input {
    width: 100% !important;
  }

  .contact-message {
    width: 100% !important;
    padding: 1.5rem;
  }

  .champs-obligatoires {
    font-family: 'Poppins', sans-serif;
    font-size: 12px;
  }

  .resp-hide {
    display: block;
  }

  .resp-show {
    display: none;
  }

  /******************HACK EDGE PB AFFICHE FORMULAIRE********************/
  @supports (-ms-ime-align:auto) {
    .contact-label {
      height: 35px;
    }
  }

  .contact-input {
    float: right;
    width: 65%;
    margin: 3px 0;
    border: 1px solid $contact-submit-bg-color;
    border-radius: 0;
    min-height: 5rem;
    font-size: 1.6rem;
    padding: 0 1rem;
  }

  .contact-message {
    float: right;
    width: 65%;
    margin: 3px 0;
    border: 1px solid $contact-submit-bg-color;
    border-radius: 0;
    font-size: 1.6rem;
  }

  .contact-captcha {
    float: right;
    width: 65%;
  }

  .contact-div-submit {
    width: 100%;
    float: right;
    position: relative;
    margin: 20px 0 50px 0;
    display: flex;
    justify-content: flex-end;
  }

  .contact-div-submit button {
    font-family: $contact-submit-font;
    font-size: $contact-submit-font-size;
    padding: 0.8rem 3rem;
    color: $contact-submit-font-color;
    border: 1px solid $contact-submit-bg-color;
    background-color: $contact-submit-bg-color;
    transition: $transition;
  }

  .contact-div-submit button:hover {
    color: $color7;
    border: 1px solid $color7;
    text-decoration: none;
    background-color: transparent;
    transition: $transition;
  }

  .contact-div-rgpd {
    position:relative;
    float: right;
    width: 65%;
    margin-top: 15px;
  }

  @media only screen and (max-width: $screen-md-min) {
    .contact-div-rgpd {
      width: 100%;
    }
  }

  .contact-rgpd {
    position:absolute;
  }

  .contact-rgpd > span > span {
    border: 0;
    padding: 0;
    background-color: transparent;
  }

  .contact-rgpd > span > span > span {
    margin: 0;
  }

  .checkbox-rgpd {
    position: absolute !important;
    left: 0 !important;
    top: 7px !important;
  }

  .confidentialite {

    font-family: $font1;
    font-size: 13px;

    label {
      font-size: 13px;
      line-height: 1.8rem;
    }

    a {

      color: #000000;
      text-decoration: underline;
      font-size: 13px;
      line-height: 1.8rem;

      :hover {
        color: #000000;
        text-decoration: none;
      }

    }

  }

  .ctrl {
    display: none;
  }

  #form-send {
    display: none;
    padding: 1.5rem;
    width: calc(90% + 1.3rem);
    margin: 2rem 0.5rem 0 auto;
    border: 1px solid green;
  }

  #form-error {
    display: none;
    padding: 1.5rem;
    width: calc(90% + 1.3rem);
    margin: 2rem 0.5rem 0 auto;
    border: 1px solid red;
  }

  .contact-bloc {
    background-color: $contact-bloc-bg;
    color: $contact-bloc-font-color;
    padding: 5rem 1.5rem;
    font-family: $contact-font;
    font-size: $contact-font-size;
    margin-top: 0.2rem;
    text-align: center;

    p {
      color: $contact-bloc-font-color;
    }

    a {
      color: $contact-bloc-font-color;
      text-decoration: none;

      :hover {
        text-decoration: underline;
      }
    }

  }

  #carte {
    width: 100%;
    height: 45rem;
    margin: 1.5rem 0;
  }

  /* Honey pot */
  .nom-2 {
    display: none;
  }

}

// --------------------------
// ACTUALITES
// --------------------------

#actualites, #sas-actualites {

  background-color: $actualite-bg-color;
  padding-top: 10rem;
  padding-bottom: 8rem;

  h1 {
    text-transform: uppercase;
  }

  h2 {
    /*font-family: $actualite-h2-font;
    font-size: $actualite-h2-font-size;
    color: $actualite-h2-color;
    font-weight: bold;
    line-height: 4rem;*/
    padding-bottom: 4rem;
    text-align: center;
  }

  img {
    max-height: 55rem;
  }

  .actualites-img {
    width: 100%;
    height: 35rem;
    background-size: cover;
    background-position: center center;
  }

  .actualites-content {
    background-color: $actualite-content-bg-color;
    padding: 1.5rem;
    min-height: 15rem;

    h3 {
      font-family: $actualite-h3-font;
      font-size: $actualite-h3-font-size;
      color: $actualite-h3-color;
      text-align: center;
      font-weight: 500;
      padding: 0.8rem 0;
    }

    p {
      text-align: center;
    }

    .bt-plus {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 8px;
      cursor: pointer;
      transition: all 0.3s;

      img {
        height: 2rem;
        width: auto;
      }

    }

  }

  .col-actualites-link {
    text-align: center;
    padding: 5rem 0 0 0;
  }

  .actualites-link {
    font-family: $actualite-submit-font;
    font-size: $actualite-submit-font-size;
    padding: 1rem 3rem 0.8rem 3rem;
    color: $actualite-submit-font-color;
    border: 1px solid $actualite-submit-bg-color;
    background-color: $actualite-submit-bg-color;
    transition: $transition;
    text-decoration: none;
  }

  .actualites-link:hover {
    color: $actualite-submit-font-color;
    text-decoration: none;
    background-color: transparent;
    transition: $transition;
  }

  .bloc-actu {
    margin: 1.5rem 0;
  }

}



// --------------------------
// SAS ACTUALITES
// --------------------------

#sas-actualites {

  background-color: $actualite-sas-bg-color;
  padding: 30rem 0 8rem 0;

  h1 {
    padding: 3rem 0;
    text-align: center;
  }

  h2, h3, h4 {
    padding: 2rem 0;
  }

  .actualites-content {
    background-color: $actualite-sas-content-bg-color;
    padding: 1.5rem;
  }

}

// --------------------------
// FOOTER
// --------------------------

footer {

  background-color: $footer-bg-color;
  padding: 2rem 0;
  font-family: $footer-font;
  font-size: $footer-font-size;
  color: $footer-font-color;

  p {
    background-color: $footer-bg-color;
    font-family: $footer-font;
    font-size: $footer-font-size;
    color: $footer-font-color;
    margin-bottom: 0;
  }

  a, p a {
    font-family: $footer-font;
    font-size: $footer-font-size;
    color: $footer-font-color;
    text-decoration: none;
  }

  a:hover, p a:hover {
    color: $footer-font-color !important;
    text-decoration: underline;
  }

  .footer-div {
    display: flex;
    align-items: center;
  }

  @media only screen and (max-width: $screen-lg-min) {
    .footer-div {
      justify-content: center !important;
      padding: 1rem 0;
    }
  }

  .footer-div-2 {
    justify-content: flex-end;
    flex-direction: column;
    align-items: flex-start;
    padding: 2rem;

    p:last-of-type {
      margin-top: 3rem;
    }
  }

  @media only screen and (max-width: $screen-lg-min) {
    .footer-div-2 {
      align-items: center;
    }
  }

  .footer-div-3 {
    justify-content: flex-end;
  }

  .footer-logo {
    height: 25rem;
    width: auto;
    filter: brightness(0) invert(1);
    -webkit-filter: brightness(0) invert(1);
  }

  .footer-res-txt {
    font-family: $footer-reseau-font;
    font-size: $footer-reseau-font-size;
  }

  .res-sociaux {

    text-align: right;

    a {

      img {
        width: $footer-reseau-size;
        height: auto;
        padding: 0.2rem 0.4rem;
        transition: $transition;

      }

      img:hover {
        filter: brightness(0%);
        -webkit-filter: brightness(0%);
        transition: $transition;
      }

    }

  }

}

// --------------------------
// MENTIONS
// --------------------------

#mentions {

  background-color: $color6;
  padding: 30rem 0 8rem 0;

  h1 {
    padding: 3rem 0;
    text-align: center;
  }

}

// --------------------------
// COOKIES
// --------------------------

.tarteaucitronAlertBigBottom {
  background: #30353B !important;
  padding: 20px 0 !important;

  .tarteaucitronAllow {
    background-color: #30353B !important;
    border: 1px solid #fff !important;
  }

  .tarteaucitronDeny {
    background-color: #fff !important;
    border: 1px solid #fff !important;
    color: #30353B !important;

    .tarteaucitronCross::before {
      color: #30353B !important;
    }
  }

  #tarteaucitronCloseAlert {
    background-color: #EDE8D1 !important;
    border: 1px solid #EDE8D1 !important;
    color: #30353B !important;
  }

}

#tarteaucitronClosePanel {
  border-radius: 10px 10px 0 0;
}

// --------------------------
// PAGINATION
// --------------------------

.pagination {
  justify-content: center;
  margin: 3rem 0;

  .page-link {
    border: none;
    color: $color7;
    font-size: 1.6rem;
  }

  .page-item.active .page-link {
    color: #fff;
    background-color: $color7;
    font-size: 1.6rem;
  }

  .page-link:hover {
    background-color: $color6;
    border-color: $color7;
  }

}

